@import "@/assets/constants/index.scss";

.single-filter {
  display: flex;
  flex-direction: column;
  // max-height: 504px;
  overflow: auto;

  .height-auto {
    max-height: inherit;
  }

  .el-checkbox {
    width: 100%;
  }
}
