@import "@/assets/constants/index.scss";

.search-input {
  .el-input__prefix svg {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 16px;
  }

  .el-input__inner {
    width: auto;
    min-width: 307px;
    background-color: $c-grey-medium;
    border-radius: 10px;
    padding-left: 48px;
  }
}
