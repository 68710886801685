@import "@/assets/constants/index.scss";

.grouped-filter {
  display: flex;
  flex-direction: column;
  max-height: 504px;
  overflow: hidden;

  .reset-filters-link {
    width: 100%;
    height: 24px;
    color: $c-blue-grey1;
    font-size: 12px;
    text-align: right;
    padding: 4px 20px;
    margin-bottom: 8px;
    cursor: pointer;
  }

  .search-input {
    margin-bottom: 16px;
    margin-left: 20px;
    margin-right: 20px;
    width: auto;

    .el-input__inner {
      width: 100%;
    }
  }

  .el-checkbox {
    width: 100%;
  }

  .check-all-checkbox {
    border-top: 1px solid $c-blue-grey4;
    border-bottom: 1px solid $c-blue-grey4;
    margin-bottom: 16px;
  }

  .el-collapse {
    border: none;
    overflow: auto;

    .el-collapse-item__wrap {
      border: none;
    }
  }

  .el-collapse-item {
    overflow: hidden;
    margin-bottom: 16px;

    position: relative;
  }

  .el-collapse-item__header {
    height: 24px;
    margin: 0px 20px;
    padding: 0 20px 0 20px;
    border: none;

    font-size: 12px;
    font-weight: $weight-semi-bold;
    color: $c-blue-grey1;

    i {
      position: absolute;
      left: 20px;
    }
  }

  .el-collapse-item__content {
    padding-bottom: 0px;
  }
}
